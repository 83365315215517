.projects__grid {
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
  grid-gap: 2em;
}

.section__title__one {
  text-align: center;
  margin-left: 145px;
}

p {
  margin-bottom: 0;
}
