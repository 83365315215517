.contact {
  flex-direction: column;
}

.section__title__four {
  text-align: center;
  margin-left: 140px;
}

.image-portrait {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../Images/portrait.png);
  height: 150px;
  width: 150px;
  margin-bottom: 40px;
}
