.skills__list {
  max-width: 450px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 1;
  background-color: transparent;
}

.section__title {
  position: relative;
  z-index: 1;
  background-color: transparent;
}

.skills__list-item {
  margin: 0.5em;
}

.section__title__two {
  text-align: center;
  margin-left: 80px;
  position: relative;
  z-index: 1;
  background-color: transparent;
}

.section-container {
  position: relative;
  z-index: 1; /* Ensure proper positioning for child elements */
}

.purple {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -180px;
  width: 80%;
  height: 100%;
  z-index: 0;
  opacity: 0.6;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../Images/orange-draw.png);
}
